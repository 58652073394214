import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../layout';
import SEO from '../components/SEO/SEO';
import config from '../../data/SiteConfig';
import useAnalytics from '../hooks/useAnalytics';

// components
import Header from '../components/Home/Header';
import SplitSection from '../components/Home/SplitSection';
import WhoWeAre from '../components/Home/WhoWeAre';
import WeAreHereSection from '../components/Home/WeAreHereSection';
import QuotesSection from '../components/Home/QuotesSection';
import FAQSection from '../components/Home/FAQSection';

import { getMessages } from '../locales';

function Landing() {
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    trackEvent('View Home Page');
  }, []);

  const data = useStaticQuery(graphql`
    query {
      allLocalesJson {
        nodes {
          quoteSection_quotes {
            src {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  const messages = getMessages();

  return (
    <Layout>
      <div className="landing-container">
        <Helmet title={config.siteTitle} />
        <SEO />
        <div className="home-page">
          <Header />
          <WeAreHereSection />
          <SplitSection />
        </div>
        <WhoWeAre />
        <QuotesSection
          quotes={messages['quoteSection_quotes']}
          images={data.allLocalesJson.nodes[0].quoteSection_quotes}
        />
        <FAQSection />
      </div>
    </Layout>
  );
}

export default Landing;
