import React, { useState } from 'react';
import { string } from 'prop-types';
import parse from 'html-react-parser';
import cn from 'classnames';

import { black } from '../../../styles/base/_variables.scss';
import AccordionIcon from '../../Icons/AccordionIcon';

const Accordion = ({ title, answer }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const onMenuClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="accordion-item">
      <button
        type="button"
        className="accordion-item-header"
        aria-label="Open Accordion"
        onClick={onMenuClick}
      >
        <p>{parse(title)}</p>
        <AccordionIcon toggled={isDropdownOpen} />
      </button>
      <div className={cn('accordion-item-content', { 'accordion-active': isDropdownOpen })}>
        <p>{parse(answer)}</p>
      </div>
    </div>
  );
};

Accordion.propTypes = {
  title: string.isRequired,
  answer: string.isRequired,
};

export default Accordion;
