import React from 'react';
import parse from 'html-react-parser';

import leftImageUrl from '../../assets/home/splitScreen-illus-1.svg';
import rightImageUrl from '../../assets/home/splitScreen-illus-2.svg';

import { getMessages } from '../../locales';

const SplitSection = () => {
  const messages = getMessages();

  return (
    <section className="home-split-section">
      <div className='container'>
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-12 col-lg-6">
            <img
              className="m-auto"
              width="500"
              src={leftImageUrl}
              alt={messages['splitSection.flexiblePlanIllus']}
            />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <h3>{messages['splitSection.flexiblePlanTitle']}</h3>
            <p className="subtitle">{parse(messages['splitSection.flexiblePlanDesc'])}</p>
          </div>
        </div>
        <div className="row align-items-center inversed-sm">
          <div className="col-sm-12 col-md-12 col-lg-6">
            <h3>{messages['splitSection.honestyTitle']}</h3>
            <p className="subtitle">{parse(messages['splitSection.honestyDesc'])}</p>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <img
              className="m-auto"
              width="500"
              src={rightImageUrl}
              alt={messages['splitSection.honestyIllus']}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SplitSection;
