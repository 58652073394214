import React from 'react';
import parse from 'html-react-parser';

import rightImageUrl from '../../assets/home/we-are-here-illus.svg';

import { getMessages } from '../../locales';

const WeAreHereSection = () => {
  const messages = getMessages();

  return (
    <section className="we-are-here-section">
      <div className="container">
        <div className="row no-gutters">
          <div className="col-sm-12 col-md-12 col-lg-6">
            <h2 className="title-lg">{messages['WeAreHereSection.title']}</h2>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <p className="subtitle">{parse(messages['WeAreHereSection.desc'])}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WeAreHereSection;
